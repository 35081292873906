import React from 'react'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'

import { useBlockingTransition } from '@wf-mfe/navigation'
import { tw } from 'workfront-twind'

export function ReactRouterUpgrade(props) {
  console.log({ props })

  return (
    <ul className={tw`p-4`}>
      <li>
        <Link to="1">One</Link>
      </li>
      <li>
        <Link to="2">Two</Link>
      </li>
    </ul>
  )
}

export function UpgradeDetailsPage(props) {
  const { block, unblock } = useBlockingTransition()
  const navigate = useNavigate()

  const { ID, subpath } = useParams()
  const { pathname } = useLocation()

  console.log({ props })

  return (
    <div className={tw`p-4 flex flex-col gap-2`}>
      <div className={`flex gap-2`}>
        <Link to="/perf/react-router-upgrade">Back</Link>
        <button onClick={() => navigate(-1)}>Also back</button>
        <button onClick={() => navigate(-1)}>Also also back</button>
        <button
          onClick={() =>
            block({
              name: 'perf',
              warning: true,
              confirmText: 'Stay',
              denyText: 'Leave',
            })
          }
        >
          Block
        </button>
        <button onClick={unblock}>Unblock</button>
        <button
          onClick={() =>
            navigate(
              ID === '1'
                ? `/perf/react-router-upgrade/2`
                : `/perf/react-router-upgrade/1`
            )
          }
        >
          Other page
        </button>
        <button
          onClick={() =>
            navigate(
              ID === '1'
                ? `/perf/react-router-upgrade/2`
                : `/perf/react-router-upgrade/1`,
              { replace: true }
            )
          }
        >
          Other page replace
        </button>
      </div>
      <h1>details page for {ID}</h1>
      <h2>subpath: {subpath}</h2>
      <p>Location: {pathname}</p>
    </div>
  )
}
